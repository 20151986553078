import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { store } from './redux/Store';
import { Provider } from 'react-redux';
// import 'react-phone-number-input/style.css';

import { Auth0Provider } from '@auth0/auth0-react';
import Config from './utils/Config';

const onRedirectCallback = (appState) => {
  // const navigate = useNavigate();
  // history.push(
  // navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const providerConfig = {
  domain: Config.auth0_domain,
  clientId: Config.auth0_clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin+'/auth',
    ...(Config.auth0_audience ? { audience: Config.auth0_audience } : null),
  },
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <Auth0Provider
      { ...providerConfig }
    >
      <App />
    </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

// git push -f origin main
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
