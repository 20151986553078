import React, { useEffect, useState } from "react";
import Map from "./Map";
// import Tableproject from "./Tableproject";
import Loading from "../../Website/Loading";
import UniversalDataTable from "../Contacts/UniversalDataTable";
import { Link } from "react-router-dom";

import Config from "../../utils/Config";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setUserMiniProfile } from "../../redux/slices/userSlice";

import swal from "sweetalert";
import { useAuth0 } from "@auth0/auth0-react";

export default function Hero() {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [site_gps, setSite_gps] = useState([]);
  const [site_name, setSite_name] = useState([]);
  const [site_address, setSite_address] = useState([]);
  const [site_id, setSite_id] = useState([]);
  const [loadMap, setLoadMap] = useState(false);

  // const [ page, setPage ] = React.useState(1);
  // const [ lastPage, setLastPage ] = React.useState(1);
  // const [ currentPage, setCurrentPage ] = React.useState(1);

  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [lastPage, setLastPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);

  const [loading, setLoading] = useState("");
  const [projectLoading, setProjectLoading] = useState("");

  // const increasePage = () => {
  //   //console.log(currentPage, lastPage);
  //   if (currentPage >= lastPage) {
  //     setPage(lastPage);
  //   } else {
  //     setPage(currentPage + 1);
  //   }
  // };

  const user = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (user?.id) {
      if (user.first_name?.length && user?.last_name?.length) {
        loadProjects();
      } else {
        navigate("/join");
      }
    } else {
      navigate("/");
    }
  }, [user, page, navigate]);

  const loadProjects = () => {
    setProjectLoading(true);
    fetch(
      `${Config.API.URL}/projects?page=${page}&sort_by=updated_at&sort_direction=DESC`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then((res) => {
        setProjectLoading(false);

        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          localStorage.removeItem("user");
          dispatch(setUserMiniProfile({}));
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setProjectLoading(false);
        console.log("init-res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setProjects(res.data.data);
          setTotal(res.data.total);

          setLastPage(res.data.last_page);
          setCurrentPage(res.data.current_page);

          setPage(res.data.current_page);
        } else {
          if (res.message === "Token is invalid.") {
            localStorage.removeItem("user");
            dispatch(setUserMiniProfile({}));
            // logout({ logoutParams: { returnTo: window.location.origin } });
          } else {
            swal("Oh no!", res.message, "error");
          }
        }
      })
      .catch((error) => {
        setProjectLoading(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  // useEffect(() => {
  //   if (page > 1) {
  //     setLoading(true);
  //     fetch(
  //       `${Config.API.URL}/projects?page=${page}&limit=10&sort_by=updated_at&sort_direction=DESC`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${user.token}`,
  //         },
  //       }
  //     )
  //       .then((res) => {
  //         // if 401 error (unauthenticated user, then log user out)
  //         if (res.status === 401) {
  //           console.log("401 Error: Unauthenticated");
  //           localStorage.removeItem("user");
  //           dispatch(setUserMiniProfile({}));
  //           navigate("/");
  //         }
  //         return res;
  //       })
  //       .then((res) => res.json())
  //       .then(async (res) => {
  //         // console.log('more-res:', res);
  //         setLoading(false);
  //         if (res.status) {
  //           setProjects([...projects, ...res.data.data]);

  //           setLastPage(res.data.last_page);
  //           setCurrentPage(res.data.current_page);

  //           setPage(res.data.current_page);
  //         } else {
  //           if (res.message === 'Token is invalid.') {
  //             localStorage.removeItem("user");
  //             dispatch(setUserMiniProfile({}));
  //             logout({ logoutParams: { returnTo: window.location.origin } });
  //           } else {
  //             swal("Oh no!", res.message, "error");
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         console.log("Error: ", error);

  //         swal(
  //           "Oh no!",
  //           "An error occured!!! while connecting to server. Please try again",
  //           "error"
  //         );
  //       });
  //   }
  // }, [user, page]);

  useEffect(() => {
    fetch(`${Config.API.URL}/sites`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          dispatch(setUserMiniProfile({}));
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        //console.log('sites-res:', res);
        if (res.status) {
          const sites = res?.data?.data;
          const site_gps = [];
          const site_names = [];
          const site_addresses = [];
          const site_ids = [];

          for (let i = 0; i < sites.length; i++) {
            const site = sites[i];
            const gps = site.site_gps.split(",");
            site_names.push(site.site_name);
            site_addresses.push(site.site_address);
            site_ids.push(site.id);

            const gps_lat = parseFloat(gps[0]);
            const gps_lng = parseFloat(gps[1]);

            if (!site_gps.find((x) => x[0] === gps_lat && x[1] === gps_lng)) {
              site_gps.push([gps_lat, gps_lng]);
            }
          }

          setSite_gps(site_gps);
          setSite_name(site_names);
          setSite_address(site_addresses);
          setSite_id(site_ids);
          setLoadMap(true);
        } else {
          if (res.message === "Token is invalid.") {
            localStorage.removeItem("user");
            dispatch(setUserMiniProfile({}));
            logout({ logoutParams: { returnTo: window.location.origin } });
          } else {
            swal("Oh no!", res.message, "error");
          }
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        setLoadMap(true);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  }, [user, navigate]);

  // const _onSearchProjects = (result, keywords) => {
  //   // console.log('result: ', result);
  //   if (result) {
  //     result.data ? setProjects(result.data) : setProjects(result);
  //     setLastPage(result.last_page);

  //     setTotal(result.data ? result?.data?.length : result?.length);
  //     setCurrentPage(result.current_page);
  //   } else {
  //     if (!keywords.length) {
  //       loadProjects();
  //     }
  //   }
  // };

  // const _onDeleteProject = (project) => {
  //   let index = projects.findIndex((x) => x.id === project.id);
  //   let data = [...projects];

  //   data.splice(index, 1);

  //   setProjects(data);
  // };

  const _onDeleteProject = (project) => {
    // console.log("project:", project);

    swal({
      title: `Are you sure?`,
      text: `You want to delete "${project.project_name}"? because once deleted, you will not be able to recover this project!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${Config.API.URL}/projects/${project.id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        })
          .then((res) => {
            // if 401 error (unauthenticated user, then log user out)
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");
              localStorage.removeItem("user");
              navigate("/");
            }
            return res;
          })
          .then((res) => res.json())
          .then(async (res) => {
            //console.log("res:", res);

            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              swal(
                `The "${project.project_name}" project has been successfully deleted!`,
                { icon: "success" }
              );
              let index = projects.findIndex((x) => x.id === project.id);
              let data = [...projects];
              data.splice(index, 1);
              setProjects(data);
              setTotal(total - 1);

              // props.onDeleteProject(project);
              // props.setTotal(props.total - 1);
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);

            swal(
              "Oh no!",
              "An error occured!!! while connecting to server. Please try again",
              "error"
            );
          });

        // swal("Poof! Your imaginary file has been deleted!", {
        //     icon: "success",
        // });
      }
    });
  };
  const _onSearchProjects = (keywords) => {
    //const keywords = e.target.value;

    if (keywords.length >= 3) {
      fetch(
        `${Config.API.URL}/projects/search?keyword=${keywords}&page=1&limit=10`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
        .then((res) => {
          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");
            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          //console.log('search-res:', res);
          if (res.status) {
            const result = res.data;

            result.data ? setProjects(result.data) : setProjects(result);
            setLastPage(result.last_page);

            setTotal(result.data ? result?.data?.length : result?.length);
            setCurrentPage(result.current_page);

            // props.onSearchProjects(res.data, keywords);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      // props.onSearchProjects(null, keywords);
      if (!keywords.length) {
        loadProjects();
      }
    }
  };

  return (
    <div className="bg-white rounded-[8px] p-4 mt-[90px] lg:mt-3 ">
      <div className="grid grid-cols-12    ">
        <div className="   col-span-12 lg:col-span-6 ">
          <ul className=" inline-flex w-full ">
            <li className=" mt-5 lg:mt-0">
              {/* <a
                //className="absolute right-[10px] top-[8px] sm:top-[2px] mt-3  border border-primary w-[110px] h-[40px] sm:w-[186px] text-center sm:h-[48px] rounded-[8px] f-f-r text-[13px] sm:text-lg text-primary  "
                href="https://dev-dashboard.boxpower.cloud/dashboard"
                target="_blank"
                rel="noreferrer"
              >
                <button className=" relative border border-primary-dark hover:border-primary w-full lg:w-[186px]  text-center h-[56px] rounded-[8px] f-f-r text-lg text-primary-dark hover:bg-primary hover:text-white ">
                  Metrics Dashboard
                </button>
              </a> */}
            </li>
            {/* <li className=' ml-3 mt-3  ' >
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.25 16.5L13.75 11L8.25 5.5" stroke="#D0D1D2" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

            </li>
            <li className=' ml-3 mt-3  w-full ' >
              <h3 className=' text-primary f-f-r text-base  ' >
                All Projects and Sites
              </h3>
            </li> */}
          </ul>
        </div>
        <div className="   col-span-12 lg:col-span-6  ">
          <div className=" lg:float-right ">
            <ul className=" lg:inline-flex w-full ">
              <li className=" mt-5 lg:mt-0 ml-3 ">
                <Link to="/view/solar/array">
                  <button className="relative border border-primary w-full lg:w-[186px]  text-center h-[56px] rounded-[8px] f-f-r text-lg text-primary  ">
                    <svg
                      className="absolute left-[6px]"
                      width="26"
                      height="25"
                      viewBox="0 0 26 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.5 13H13.5V18C13.5 18.2652 13.3946 18.5196 13.2071 18.7071C13.0196 18.8946 12.7652 19 12.5 19C12.2348 19 11.9804 18.8946 11.7929 18.7071C11.6054 18.5196 11.5 18.2652 11.5 18V13H6.5C6.23478 13 5.98043 12.8946 5.79289 12.7071C5.60536 12.5196 5.5 12.2652 5.5 12C5.5 11.7348 5.60536 11.4804 5.79289 11.2929C5.98043 11.1054 6.23478 11 6.5 11H11.5V6C11.5 5.73478 11.6054 5.48043 11.7929 5.29289C11.9804 5.10536 12.2348 5 12.5 5C12.7652 5 13.0196 5.10536 13.2071 5.29289C13.3946 5.48043 13.5 5.73478 13.5 6V11H18.5C18.7652 11 19.0196 11.1054 19.2071 11.2929C19.3946 11.4804 19.5 11.7348 19.5 12C19.5 12.2652 19.3946 12.5196 19.2071 12.7071C19.0196 12.8946 18.7652 13 18.5 13Z"
                        fill="#ED583B"
                      />
                    </svg>
                    New Customer
                  </button>
                </Link>
              </li>
              <li className="mt-5 lg:mt-0 ml-3 ">
                <Link to="/project/create">
                  <button className="relative border bg-primary  w-full lg:w-[186px] text-center h-[56px] rounded-[8px] f-f-r text-lg text-white  ">
                    <svg
                      className="absolute left-[6px]"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 13H13V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H11V6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13Z"
                        fill="white"
                      />
                    </svg>
                    New Project
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* map section started */}
      {loadMap ? (
        <div className="my-4 ">
          <Map
            site_gps={site_gps}
            site_name={site_name}
            site_address={site_address}
            site_id={site_id}
          />
        </div>
      ) : (
        <div
          className="map-view"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <p>Map is Loading...</p>
        </div>
      )}

      {/* map section ended */}
      {projectLoading ? (
        <p style={{ textAlign: "center", paddingTop: 10, paddingBottom: 10 }}>
          Loading...
        </p>
      ) : (
        <div className=" mb-3 bg-white rounded-[8px] p-4">
          <UniversalDataTable
            table_label="All Projects"
            header_format={[
              // { column: "contacts", label: "Project Contacts" },
              { column: "project_name", label: "Project Name" },
              { column: "project_type", label: "Project Type" },
              { column: "utility", label: "Utility Name" },
              { column: "sites", label: "Number of Sites" },
              { column: "created_at", label: "Date Added" },
              { column: "modified_by", label: "Last modified by" },
              // { column: "updated_at", label: "Last Modified" },
            ]}
            data={projects}
            data_format={[
              // { column: "contacts", keys: ["contacts"] },
              { column: "project_name", keys: ["project_name"] },
              { column: "project_type", keys: ["project_type"] },
              { column: "utility", keys: ["utility"] },
              { column: "sites", keys: ["sites"] },
              { column: "created_at", keys: ["created_at"] },
              { column: "modified_by", keys: ["modified_by"] },
              // { column: "updated_at", keys: ["updated_at"] },
            ]}
            columns_format={false}
            total_items={total}
            setPage={setPage}
            lastPage={lastPage}
            currentPage={currentPage}
            onCreateItem={false}
            onViewItem={(project) => navigate(`/project/view/${project?.id}`)}
            onDeleteItem={_onDeleteProject}
            onEditItem={false}
            onSearchData={_onSearchProjects}
          />
        </div>
      )}

      {/* table  section started */}
      {/* 
      <Tableproject
        projects={projects}
        total={total}
        setTotal={setTotal}
        onDeleteProject={_onDeleteProject}
        onSearchProjects={_onSearchProjects}
      /> 
      */}
      {/* table section ended */}

      {/* <div className="inline-flex w-full justify-center mt-5 mb-10">
        {loading ? (
          "Loading..."
        ) : currentPage < lastPage ? (
          <button
            onClick={increasePage}
            className=" bg-white text-base text-primary border border-primary  w-[127px] h-[50px] rounded-[25px] mr-1 captilize "
          >
            Load More
          </button>
        ) : null}
      </div> */}
    </div>
  );
}
