import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import Config from "../../utils/Config";
import { useSelector } from "react-redux";
import swal from "sweetalert";

import Spinner from "../../assets/spinner/spinner.gif";
import UploadIcon from "../../assets/upload-icon.png";

export default function Hero() {
  const user = useSelector((state) => state.user.profile);

  const homer_file = React.useRef(null);

  const navigate = useNavigate();
  const { site_id } = useParams();

  const [scenarioName, setScenarioName] = React.useState("");
  const [PVTilt, setPVTilt] = React.useState("");
  const [PVAzimuth, setPVAzimuth] = React.useState("");
  const [solarPVCapacity, setSolarPVCapacity] = React.useState("");
  const [batteryStorageCapacity, setBatteryStorageCapacity] =
    React.useState("");
  const [batteryInverterCapacity, setBatteryInverterCapacity] =
    React.useState("");
  const [generatorQuantity, setGeneratorQuantity] = React.useState("");
  const [generatorSize, setGeneratorSize] = React.useState("");
  const [renewableFraction, setRenewableFraction] = React.useState("");
  const [generatorRuntime, setGeneratorRuntime] = React.useState("");
  const [fuelConsumption, setFuelConsumption] = React.useState("");
  const [fuelTankSize, setFuelTankSize] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadReport, setUploadReport] = React.useState("");

  const [solarProduct] = React.useState({
    id: 7,
    solar_product_name: "S48MT",
    solar_product_quantity: 1,
    solar_product_label: "S48MT",
    solar_product_unit_capacity: 25.44,
    solar_product_total_capacity: 25.44,
    solar_product_unit_cost: 29360,
    solar_product_notes: "48 panel BoxPower SolarContainer",
    solar_product_manufacturer: "BoxPower",
    solar_product_model: "S48MT",
    solar_product_part_number: "S48MT",
    solar_product_certifications: "UL 1703",
    solar_product_tilt: 30,
    solar_product_azimuth: 180,
    solar_product_string_length: 8,
    solar_product_string_quantity: 6,
    solar_product_pmax: 25440,
    solar_product_vmp: 325.6,
    solar_product_imp: 64.92,
    solar_product_voc: 389.6,
    solar_product_isc: 68.88,
    solar_product_voc_min_temp: 368,
    solar_product_isc_min_temp: 55.56,
    others: null,
    created_at: "2024-02-08T03:05:49.000000Z",
    updated_at: "2024-02-08T03:05:49.000000Z",
  });
  const [batteryProduct] = React.useState({
    id: 1,
    battery_product_name: "B3.8LFP",
    battery_product_quantity: 1,
    battery_product_label: "B3.8LFP",
    battery_product_unit_capacity: 3.8,
    battery_product_total_capacity: 3.8,
    battery_product_unit_cost: 20000,
    battery_product_notes: "BoxPower 3.8kWh battery",
    battery_product_manufacturer: "BoxPower",
    battery_product_model: "B3.8LFP",
    battery_product_part_number: "B3.8LFP",
    battery_product_certifications: "UL 1642, UL 1973, UL 9540A",
    battery_product_chemistry: "Lithium Ferro Phosphate (LFP)",
    battery_product_dc_voltage: 51.2,
    battery_product_amp_hours: 75,
    battery_product_storage_capacity: 3.8,
    battery_product_max_discharge_rate: 1.9,
    battery_product_max_charge_rate: 1.9,
    battery_product_discharge_depth: 100,
    others: null,
    created_at: "2024-02-08T19:42:50.000000Z",
    updated_at: "2024-02-08T19:42:50.000000Z",
  });
  const [inverterProduct] = React.useState({
    id: 5,
    inverter_product_name: "ISP27",
    inverter_product_quantity: 1,
    inverter_product_label: "ISP27",
    inverter_product_unit_capacity: 27.2,
    inverter_product_total_capacity: 27.2,
    inverter_product_unit_cost: 28793,
    inverter_product_notes: "Single phase system with 4 schneider conext pro",
    inverter_product_manufacturer: "BoxPower",
    inverter_product_model: "ISP27",
    inverter_product_part_number: "ISP27",
    inverter_product_certifications: "UL1741",
    inverter_product_output_power: 27200,
    inverter_product_output_voltage: "120/240",
    inverter_product_output_frequency: "60",
    inverter_product_max_input_current: 720,
    inverter_product_max_output_current: 140,
    others: null,
    created_at: "2024-02-08T03:39:16.000000Z",
    updated_at: "2024-02-08T03:39:16.000000Z",
  });
  const [mountingProduct] = React.useState({
    id: 2,
    mounting_product_name: "C2Box",
    mounting_product_quantity: 1,
    mounting_product_label: "C2Box",
    mounting_product_unit_capacity: 60,
    mounting_product_total_capacity: 60,
    mounting_product_unit_cost: 20660,
    mounting_product_notes: "C2Box - Enclosure",
    mounting_product_manufacturer: "BoxPower",
    mounting_product_model: "C2Box",
    mounting_product_part_number: "C2Box",
    mounting_product_certifications: "ISO 6346",
    mounting_min_clear_height: 20,
    others: null,
    created_at: "2024-02-08T20:15:50.000000Z",
    updated_at: "2024-02-08T20:15:50.000000Z",
  });
  const [generatorProduct] = React.useState({
    id: 2,
    generator_product_name: "PGP35",
    generator_product_quantity: 1,
    generator_product_label: "PGP35",
    generator_product_unit_capacity: 35,
    generator_product_total_capacity: 35,
    generator_product_unit_cost: 21933,
    generator_product_notes: "35 kW prime-power-rated propane generator",
    generator_product_manufacturer: "BoxPower",
    generator_product_model: "PGP35",
    generator_product_part_number: "PGP35",
    generator_product_certifications: "UL 2200, UL 6200, UL 1236, UL 489",
    generator_frequency: "60",
    generator_phase: "Single-Phase, Three-Phase",
    generator_rated_voltage: "120/208/240/277/480",
    generator_real_standby_power: 35,
    generator_apparent_standby_power: 44,
    generator_real_prime_power: 32,
    generator_apparent_prime_power: 39,
    generator_power_factor: 0.8,
    generator_efficiency: 90,
    generator_max_current: 146,
    generator_continuous_current: 131,
    generator_breaker_current: 150,
    others: null,
    created_at: "2024-02-08T21:12:31.000000Z",
    updated_at: "2024-02-08T21:12:31.000000Z",
  });
  const [chargecontrollerProduct] = React.useState({
    id: 3,
    chargecontroller_product_name: "MPPT-100-600",
    chargecontroller_product_quantity: 1,
    chargecontroller_product_label: "MPPT-100-600",
    chargecontroller_product_unit_capacity: 6,
    chargecontroller_product_total_capacity: 6,
    chargecontroller_product_unit_cost: 3200,
    chargecontroller_product_notes: "6 kW MPPT 100 600",
    chargecontroller_product_manufacturer: "Schneider",
    chargecontroller_product_model: "MPPT-100-600",
    chargecontroller_product_part_number: "865-1034",
    chargecontroller_product_certifications: "UL1741, CSA 107.1",
    chargecontroller_max_pv_capacity: 6,
    chargecontroller_max_string_length: 10,
    others: null,
    created_at: "2024-02-08T21:36:11.000000Z",
    updated_at: "2024-02-08T21:36:11.000000Z",
  });

  const [fenceProduct] = React.useState({
    id: 1,
    fence_product_height: 0,
    fence_product_type: "",
    fence_product_topper: "",
    fence_product_sound_attenuation: "",
    fence_product_attenuation_spec: "",
    fence_product_manufacturer: "",
    fence_product_model: "",
    fence_product_part_number: "",
    others: [],
  });

  const [microgridSystemSizingType, setMicrogridSystemSizingType] =
    React.useState("homer-optimization-results"); // homer-optimization-results, microgrid-optimization-feature
  const [microgridSystemSizingStage, setMicrogridSystemSizingStage] =
    React.useState(1);

  const [scenario, setScenario] = React.useState({});

  React.useEffect(() => {
    loadProducts();
  }, [user, navigate]);

  const loadProducts = () => {
    setLoading(true);
    fetch(`${Config.API.URL}/products?page=${1}&limit=10`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        setLoading(false);

        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoading(false);
        console.log("init-res:", res.data);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          // const solar_products = res.data;
          // const battery_products = res.data;
          // const inverter_products = res.data;
          // const mounting_products = res.data;
          // const generator_products = res.data;
          // const chargecontroller_products = res.data;
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const submitScenarios = () => {
    if (scenarioName.length) {
      setLoading(true);
      const payload = {
        site_id: site_id,
        scenario_name: scenarioName,
        pv_tilt: PVTilt ? PVTilt : 0,
        pv_azimuth: PVAzimuth ? PVAzimuth : 0,
        solar_pv_capacity: solarPVCapacity ? solarPVCapacity : 0,
        battery_storage_capacity: batteryStorageCapacity
          ? batteryStorageCapacity
          : 0,
        battery_inverter_capacity: batteryInverterCapacity
          ? batteryInverterCapacity
          : 0,
        generator_quantity: generatorQuantity ? generatorQuantity : 0,
        generator_size: generatorSize ? generatorSize : 0,
        model_renewable_fraction: renewableFraction ? renewableFraction : 0,
        generator_runtime: generatorRuntime ? generatorRuntime : 0,
        fuel_consumption: fuelConsumption ? fuelConsumption : 0,
        fuel_tank_size: fuelTankSize ? fuelTankSize : 0,
        solar_product: [],
        battery_product: [],
        inverter_product: [],
        mounting_product: [],
        generator_product: [],
        chargecontroller_product: [],
        fence_product: [],
      };

      fetch(`${Config.API.URL}/scenarios`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);
          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");

            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);
          console.log("res:", res);
          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setScenario(res.data);
            setMicrogridSystemSizingStage(2);
            // navigate(`/scenarios/view/${site_id}`);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  const triggerUploadHomer = () => {
    //$('#upload-homer-file').trigger('click');
    document.getElementById("upload-homer-file").click();
  };

  /**
   * Handles upload of HOMER file to server
   */
  // const uploadHomer = (e) => {
  //   var formData   = new FormData();
  //   var homer_file = e.target.files[0]; // homer_file.current.files[0];

  //   console.log('homer_file:', homer_file);

  //   if (homer_file) {
  //     setUploading(true);
  //     setLoading(true);
  //     formData.append('scenario_id', scenario?.id);
  //     formData.append('file', homer_file);

  //     // this.setState({ upload: true }, () => {
  //     // send API request to upload HOMER file for this project
  //     fetch(Config.API.URL+'/system-optimization/upload-homer-file', {
  //       method: 'POST',
  //       headers: {Accept: 'application/json', Authorization: `Bearer ${user.token}` },
  //       body: formData,
  //     }).then((res) => {
  //       // if 401 error (unauthenticated user, then log user out)
  //       if ((res.status) === 401) {
  //         console.log("401 Error: Unauthenticated");

  //         localStorage.removeItem("user");
  //         navigate("/");
  //       }
  //       return res;
  //     }).then((res) => res.json()).then( async (res) => {
  //       console.log('upload-res:', res);
  //       if (res.status) {
  //         //navigate(`/scenarios/optimization/${scenario.project_id}/${scenario.id}`);
  //         navigate(`/scenarios/view/${site_id}`);
  //         // this.setState({ homer: true, upload: false, homer_name: res.data });
  //       } else {
  //         // this.setState({ homer: false, upload: false });
  //         // alert(res.message);
  //         swal("Oh no!", res.message, "error");
  //       }
  //       setUploading(false);
  //       setLoading(false);
  //     }).catch((error) => {
  //       console.log('Error: ', error);
  //       setUploading(false);
  //       setLoading(false);
  //       //this.setState({ homer: false, upload: false });
  //       swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
  //     });
  //     //});
  //   } else {
  //     setUploading(false);
  //     setLoading(false);
  //     swal("Oh no!", "Please select homer file, to continue", "error");
  //   }
  // }

  const uploadHomer = (e) => {
    const formData = new FormData();
    const homer_file = e.target.files[0];

    console.log("homer_file:", homer_file);

    if (homer_file) {
      setUploading(true);
      setLoading(true);
      setUploadReport("Uploading your HOMER file...");
      formData.append("scenario_id", scenario?.id);
      formData.append("file", homer_file);

      // Create a new XMLHttpRequest
      const xhr = new XMLHttpRequest();

      // Event listener for upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          console.log(`Upload progress: ${percentComplete}%`);
          // Update progress state if you want to display it in the UI
          setUploadProgress(percentComplete);

          // 0%: Uploading your HOMER file...
          // 30%: Extracting relevant data from the microgrid simulation results...
          // 60%: Aggregating data into hourly and monthly charts...
          // 95%: Almost done, finalizing the process.. (edited)

          if (percentComplete >= 0 && percentComplete < 30) {
            setUploadReport("Uploading your HOMER file...");
          } else if (percentComplete >= 30 && percentComplete < 60) {
            setUploadReport(
              "Extracting relevant data from the microgrid simulation results..."
            );
          } else if (percentComplete >= 60 && percentComplete < 95) {
            setUploadReport(
              "Aggregating data into hourly and monthly charts..."
            );
          } else {
            setUploadReport("Almost done, finalizing the process...");
          }
        }
      };

      // Event listener for upload completion
      xhr.onload = () => {
        if (xhr.status === 200) {
          const res = JSON.parse(xhr.responseText);
          console.log("upload-res:", res);
          if (res.status) {
            //props.onReceiveHomer(res.data);
            navigate(`/scenarios/view/${site_id}`);
          } else {
            swal("Oh no!", res.message, "error");
          }
        } else if (xhr.status === 401) {
          console.log("401 Error: Unauthenticated");
          localStorage.removeItem("user");
          navigate("/");
        } else {
          swal("Oh no!", "An error occurred during the upload.", "error");
        }
        setUploading(false);
        setLoading(false);
      };

      // Event listener for upload error
      xhr.onerror = () => {
        console.log("Error: ", xhr.statusText);
        setUploading(false);
        setLoading(false);
        swal(
          "Oh no!",
          "An error occurred while connecting to the server. Please try again",
          "error"
        );
      };

      // Open the connection and send the formData
      xhr.open(
        "POST",
        `${Config.API.URL}/system-optimization/upload-homer-file`,
        true
      );
      xhr.setRequestHeader("Accept", "application/json");
      xhr.setRequestHeader("Authorization", `Bearer ${user.token}`);
      xhr.send(formData);
    } else {
      setUploading(false);
      setLoading(false);
      swal("Oh no!", "Please select a HOMER file to continue", "error");
    }
  };

  // const _onChangeSolarProducts = (e) => {
  //   const data = { ...solarProducts[0] };

  //   data[e.target.name] = e.target.value;

  //   setSolarProducts([data]);
  // };

  // const _onChangeBatteryProducts = (e) => {
  //   const data = { ...batteryProducts[0] };

  //   data[e.target.name] = e.target.value;

  //   setBatteryProducts([data]);
  // };

  // const _onChangeInverterProducts = (e) => {
  //   const data = { ...inverterProducts[0] };

  //   data[e.target.name] = e.target.value;

  //   setInverterProducts([data]);
  // };

  // const _onChangeMountingProducts = (e) => {
  //   const data = { ...mountingProducts[0] };

  //   data[e.target.name] = e.target.value;

  //   setMountingProducts([data]);
  // };

  // const _onChangeGeneratorProducts = (e) => {
  //   const data = { ...generatorProducts[0] };

  //   data[e.target.name] = e.target.value;

  //   setGeneratorProducts([data]);
  // };

  // const _onChangeChargecontrollerProducts = (e) => {
  //   const data = { ...chargecontrollerProducts[0] };

  //   data[e.target.name] = e.target.value;

  //   setChargecontrollerProducts([data]);
  // };

  return (
    <>
      <div className="bg-white rounded-[8px] p-6  mt-[90px] lg:mt-3 ">
        {microgridSystemSizingStage === 1 ? (
          <>
            <h2 className=" f-f-sm text-3xl text-black text-center ">
              Create A New Scenario
            </h2>
            <div className="2xl:px-20 ">
              <h2 className=" f-f-sm text-xl capitalize  text-primary  mt-10  text-center ">
                {" "}
                Microgrid Optimization Summary
              </h2>
              <div className="grid grid-cols-12  ">
                {/* .....................  Electric input started ..............*/}

                <div className=" col-span-12 lg:col-span-3 mt-5 ">
                  <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                    Scenario Name <span className="text-primary">*</span>
                  </h2>
                </div>
                <div className=" col-span-12 lg:col-span-9 mt-5 ">
                  <input
                    type="text"
                    onChange={(e) => setScenarioName(e.target.value)}
                    value={scenarioName}
                    className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                  />
                </div>

                {/* input section started */}
                {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                  <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                    Average PV Tilt
                  </h2>
                </div>
                <div className=" col-span-12 lg:col-span-7 mt-5 ">
                  <input
                    type="number"
                    onChange={(e) => setPVTilt(e.target.value)}
                    value={PVTilt}
                    className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                  />
                </div>
                
                <div className=" col-span-12 lg:col-span-5 mt-5 ">
                  <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                    Average PV Azimuth
                  </h2>
                </div>
                <div className=" col-span-12 lg:col-span-7 mt-5 ">
                  <input
                    type="number"
                    onChange={(e) => setPVAzimuth(e.target.value)}
                    value={PVAzimuth}
                    className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                  />
                </div> */}
              </div>

              <h2 className=" f-f-sm text-xl capitalize text-primary mt-10 text-center ">
                Microgrid System Sizing
              </h2>

              <div className=" mt-10 text-center  ">
                <ul className=" inline-flex  ">
                  <li className="p-2">
                    <button
                      onClick={() =>
                        setMicrogridSystemSizingType(
                          "homer-optimization-results"
                        )
                      }
                      className={`${
                        microgridSystemSizingType ===
                        "homer-optimization-results"
                          ? "bg-blue-950 border-blue-950 text-white"
                          : "bg-white border-primary text-gray"
                      } border text-lg f-f-r  w-[250px] h-[180px] rounded-[8px] captilize `}
                    >
                      Provide HOMER <br />
                      Optimization Results
                    </button>
                  </li>
                  <li className="p-2">
                    <button
                      onClick={() =>
                        setMicrogridSystemSizingType(
                          "microgrid-optimization-feature"
                        )
                      }
                      className={`${
                        microgridSystemSizingType ===
                        "microgrid-optimization-feature"
                          ? "bg-blue-950 border-blue-950 text-white"
                          : "bg-white border-primary text-gray"
                      } border text-lg f-f-r w-[250px] h-[180px] rounded-[8px] captilize `}
                    >
                      Microgrid Optimization
                      <br />
                      feature (coming soon)
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="2xl:px-20 ">
              <h2 className=" f-f-sm text-xl capitalize  text-primary  mt-10  text-center ">
                {" "}
                Provide HOMER Optimization Results
              </h2>

              <div className="grid grid-cols-12 mt-5 gap-4  ">
                <div className="   col-span-12 sm:col-span-6">
                  {/* crd started */}
                  <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-2 relative  ">
                    <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                      Optimization Summary
                    </h2>
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Average PV Tilt
                      <span className=" float-right text-black ">
                        {scenario?.pv_tilt}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Average PV Azimuth
                      <span className=" float-right text-black ">
                        {scenario?.pv_azimuth}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Solar PV Capacity (kW)
                      <span className=" float-right text-black ">
                        {scenario?.solar_pv_capacity}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Battery Storage Capacity (kWh)
                      <span className=" float-right text-black ">
                        {scenario?.battery_storage_capacity}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Battery Inverter Capacity (kW)
                      <span className=" float-right text-black ">
                        {scenario?.battery_inverter_capacity}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Generator Quantity
                      <span className=" float-right text-black ">
                        {scenario?.generator_quantity}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Generator Size (kW)
                      <span className=" float-right text-black ">
                        {scenario?.generator_size}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Renewable Fraction (%)
                      <span className=" float-right text-black ">
                        {scenario?.model_renewable_fraction}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Generator Runtime (hours)
                      <span className=" float-right text-black ">
                        {scenario?.generator_runtime}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Fuel Consumption (gallons)
                      <span className=" float-right text-black ">
                        {scenario?.fuel_consumption}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Fuel Tank Size (gallons)
                      <span className=" float-right text-black ">
                        {scenario?.fuel_tank_size}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                  </div>
                </div>

                <div className="   col-span-12 sm:col-span-6">
                  <div className=" bg-white p-[20px] mt-2 relative  ">
                    <h2 className=" f-f-sm text-xl capitalize text-primary mb-5 mt-5 text-center ">
                      {" "}
                      Upload the Results of a HOMER Optimization
                    </h2>
                    <button
                      onClick={
                        uploading
                          ? () =>
                              swal(
                                "Please Wait!",
                                "Uploading HOMER file is on progress!",
                                "info"
                              )
                          : triggerUploadHomer
                      }
                      className={`bg-blue-100 border-blue-950 text-black border text-lg f-f-r w-[100%] h-[300px] rounded-[8px] captilize `}
                    >
                      <img
                        src={uploading ? Spinner : UploadIcon}
                        className="w-[45px] h-auto 2xl:w-[96px] text-center"
                        style={{ marginLeft: "auto", margin: "auto" }}
                        alt=""
                      />
                      <p className="mt-2">
                        {uploading
                          ? `${uploadProgress}% - ${uploadReport}`
                          : "Choose a file"}
                      </p>
                    </button>
                    <p className=" f-f-sm mb-5 mt-5 text-center ">
                      {" "}
                      click <a href="#">here</a> to download a sample HOMER
                      Optimization file
                    </p>

                    <input
                      type="file"
                      id="upload-homer-file"
                      onChange={uploadHomer}
                      style={{ display: "none" }}
                      ref={homer_file}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* .....................  Electric input ended ..............*/}
        {/* ................ Parameters started */}

        {/* <div className="2xl:px-20 ">
          <div className="grid grid-cols-12   ">
            
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Solar PV Capacity (kW)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                type="number"
                onChange={(e) => setSolarPVCapacity(e.target.value)}
                value={solarPVCapacity}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Battery Storage Capacity (kWh)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                type="number"
                onChange={(e) => setBatteryStorageCapacity(e.target.value)}
                value={batteryStorageCapacity}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Battery Inverter Capacity (kW)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                type="number"
                onChange={(e) => setBatteryInverterCapacity(e.target.value)}
                value={batteryInverterCapacity}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Generator Quantity
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                type="number"
                onChange={(e) => setGeneratorQuantity(e.target.value)}
                value={generatorQuantity}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Generator Size (kW)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                type="number"
                onChange={(e) => setGeneratorSize(e.target.value)}
                value={generatorSize}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Renewable Fraction (%)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                type="number"
                onChange={(e) => setRenewableFraction(e.target.value)}
                value={renewableFraction}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Generator Runtime (hours)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                type="number"
                onChange={(e) => setGeneratorRuntime(e.target.value)}
                value={generatorRuntime}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Fuel Consumption (gallons)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                type="number"
                onChange={(e) => setFuelConsumption(e.target.value)}
                value={fuelConsumption}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Fuel Tank Size (gallons)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                type="number"
                onChange={(e) => setFuelTankSize(e.target.value)}
                value={fuelTankSize}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
          </div>
        </div> */}

        {/* ..............Design Parameters end .................... */}

        {/*  Solar product section started */}
        {/* <div className='2xl:px-20 ' >
          <h2 className=" f-f-sm text-lg capitalize  text-primary  mt-10  text-center " >Solar Product</h2>
          { solarProducts.map((product, i) => (
            <div key={i} className="grid grid-cols-12 mt-0 gap-4 sm:gap-0    ">
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Name *:</h2>
                <input onChange={_onChangeSolarProducts} value={product.solar_product_name} name='solar_product_name' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Quantity *:</h2>
                <input type='number' onChange={_onChangeSolarProducts} value={product.solar_product_quantity} name='solar_product_quantity' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Unit Capacity *:</h2>
                <input type='number' onChange={_onChangeSolarProducts} value={product.solar_product_unit_capacity} name='solar_product_unit_capacity' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Label</h2>
                <input type='text' onChange={_onChangeSolarProducts} value={product.solar_product_label} name='solar_product_label' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Comments or notes</h2>
                <input type='text' onChange={_onChangeSolarProducts} value={product.solar_product_notes} name='solar_product_notes' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Total Capacity</h2>
                <input type='number' onChange={_onChangeSolarProducts} value={product.solar_product_total_capacity} name='solar_product_total_capacity' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Manufacturer</h2>
                <input type='text' onChange={_onChangeSolarProducts} value={product.solar_product_manufacturer} name='solar_product_manufacturer' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Model</h2>
                <input type='text' onChange={_onChangeSolarProducts} value={product.solar_product_model} name='solar_product_model' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Part Number</h2>
                <input type='text' onChange={_onChangeSolarProducts} value={product.solar_product_part_number} name='solar_product_part_number' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Certifications</h2>
                <input type='text' onChange={_onChangeSolarProducts} value={product.solar_product_certifications} name='solar_product_certifications' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Degree Tilt</h2>
                <input type='number' onChange={_onChangeSolarProducts} value={product.solar_product_tilt} name='solar_product_tilt' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Azimuth</h2>
                <input type='number' onChange={_onChangeSolarProducts} value={product.solar_product_azimuth} name='solar_product_azimuth' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >PV String Length</h2>
                <input type='number' onChange={_onChangeSolarProducts} value={product.solar_product_string_length} name='solar_product_string_length' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Number of PV strings</h2>
                <input type='number' onChange={_onChangeSolarProducts} value={product.solar_product_string_quantity} name='solar_product_string_quantity' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Nominal Max Power (W)</h2>
                <input type='number' onChange={_onChangeSolarProducts} value={product.solar_product_pmax} name='solar_product_pmax' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Voltage at Maximum Power (V)</h2>
                <input type='number' onChange={_onChangeSolarProducts} value={product.solar_product_vmp} name='solar_product_vmp' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Current at Maximum Power (I)</h2>
                <input type='number' onChange={_onChangeSolarProducts} value={product.solar_product_imp} name='solar_product_imp' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Open Circuit Voltage (V)</h2>
                <input type='text' onChange={_onChangeSolarProducts} value={product.solar_product_voc} name='solar_product_voc' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Short Circuit Current (I)</h2>
                <input type='text' onChange={_onChangeSolarProducts} value={product.solar_product_isc} name='solar_product_isc' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Max Voc at Min Temp (C)</h2>
                <input type='text' onChange={_onChangeSolarProducts} value={product.solar_product_voc_min_temp} name='solar_product_voc_min_temp' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Max Isc at Min Temp (C)</h2>
                <input type='text' onChange={_onChangeSolarProducts} value={product.solar_product_isc_min_temp} name='solar_product_isc_min_temp' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
            </div>
          )) }
          
          <div className=' my-4 text-center ' >
            <button className=' f-f-r text-lg text-primary border border-primary  w-[204px] h-[38px]  ' >+ Add new Row</button>
          </div>
        </div> */}
        {/* Solar product section edned */}

        {/*  Battery product section started */}
        {/* <div className='2xl:px-20 ' >
          <h2 className=" f-f-sm text-lg capitalize  text-primary  mt-10  text-center " >Battery Product</h2>
          { batteryProducts.map((product, i) => (
            <div key={i} className="grid grid-cols-12 mt-0 gap-4 sm:gap-0    ">
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Name *:</h2>
                <input onChange={_onChangeBatteryProducts} value={product.battery_product_name} name="battery_product_name" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Quantity *:</h2>
                <input type='number' onChange={_onChangeBatteryProducts} value={product.battery_product_quantity} name="battery_product_quantity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Unit Capacity *:</h2>
                <input type='number' onChange={_onChangeBatteryProducts} value={product.battery_product_unit_capacity} name="battery_product_unit_capacity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product label:</h2>
                <input type='text' onChange={_onChangeBatteryProducts} value={product.battery_product_label} name="battery_product_label" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4' >Comments or Notes:</h2>
                <input type='text' onChange={_onChangeBatteryProducts} value={product.battery_product_notes} name="battery_product_notes" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4' >Total Capacity:</h2>
                <input type='number' onChange={_onChangeBatteryProducts} value={product.battery_product_total_capacity} name="battery_product_total_capacity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4' >Manufacturer:</h2>
                <input type='text' onChange={_onChangeBatteryProducts} value={product.battery_product_manufacturer} name="battery_product_manufacturer" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4' >Model:</h2>
                <input type='text' onChange={_onChangeBatteryProducts} value={product.battery_product_model} name="battery_product_model" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Part Number:</h2>
                <input type='text' onChange={_onChangeBatteryProducts} value={product.battery_product_part_number} name="battery_product_part_number" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Certifications:</h2>
                <input type='text' onChange={_onChangeBatteryProducts} value={product.battery_product_certifications} name="battery_product_certifications" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Battery Chemistry:</h2>
                <input type='text' onChange={_onChangeBatteryProducts} value={product.battery_product_chemistry} name="battery_product_chemistry" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Dc Voltage - Nominal (V):</h2>
                <input type='number' onChange={_onChangeBatteryProducts} value={product.battery_product_dc_voltage} name="battery_product_dc_voltage" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Amp-Hours (Ah):</h2>
                <input type='number' onChange={_onChangeBatteryProducts} value={product.battery_product_amp_hours} name="battery_product_amp_hours" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Storage Capacity (kWh):</h2>
                <input type='number' onChange={_onChangeBatteryProducts} value={product.battery_product_storage_capacity} name="battery_product_storage_capacity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Max Continuous Discharge Rate (kW):</h2>
                <input type='number' onChange={_onChangeBatteryProducts} value={product.battery_product_max_discharge_rate} name="battery_product_max_discharge_rate" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Max Continuous Charge Rate (kW):</h2>
                <input type='number' onChange={_onChangeBatteryProducts} value={product.battery_product_max_charge_rate} name="battery_product_max_charge_rate" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Depth of Discharge (%):</h2>
                <input type='number' onChange={_onChangeBatteryProducts} value={product.battery_product_discharge_depth} name="battery_module_discharge_depth" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
            </div>
          )) }
          
          <div className=' my-4 text-center ' >
            <button className=' f-f-r text-lg text-primary border border-primary  w-[204px] h-[38px]  ' >+ Add new Row</button>
          </div>
        </div> */}
        {/* Battery product section edned */}

        {/*  Inverter Product(s) section started */}
        {/* <div className='2xl:px-20 ' >
          <h2 className=" f-f-sm text-lg capitalize text-primary mt-10  text-center " >Inverter Product</h2>
          { inverterProducts.map((product, i) => (
            <div key={i} className="grid grid-cols-12 mt-0 gap-4 sm:gap-0    ">
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Name *:</h2>
                <input onChange={_onChangeInverterProducts} value={product.inverter_product_name} name='inverter_product_name' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Quantity *:</h2>
                <input type='number' onChange={_onChangeInverterProducts} value={product.inverter_product_quantity} name='inverter_product_quantity' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Unit Capacity *:</h2>
                <input type='number' onChange={_onChangeInverterProducts} value={product.inverter_product_unit_capacity} name='inverter_product_unit_capacity' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product label:</h2>
                <input type='text' onChange={_onChangeInverterProducts} value={product.inverter_product_label} name='inverter_product_label' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Comments or Notes:</h2>
                <input type='text' onChange={_onChangeInverterProducts} value={product.inverter_product_notes} name='inverter_product_notes' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Total Capacity:</h2>
                <input type='number' onChange={_onChangeInverterProducts} value={product.inverter_product_total_capacity} name='inverter_product_total_capacity' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Manufacturer:</h2>
                <input type='text' onChange={_onChangeInverterProducts} value={product.inverter_product_manufacturer} name='inverter_product_manufacturer' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Model:</h2>
                <input type='text' onChange={_onChangeInverterProducts} value={product.inverter_product_model} name='inverter_product_model' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Part Number:</h2>
                <input type='text' onChange={_onChangeInverterProducts} value={product.inverter_product_part_number} name='inverter_product_part_number' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Certifications:</h2>
                <input type='text' onChange={_onChangeInverterProducts} value={product.inverter_product_certifications} name='inverter_product_certifications' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product output Power (W):</h2>
                <input type='number' onChange={_onChangeInverterProducts} value={product.inverter_product_output_power} name='inverter_product_output_power' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product output Voltage (V):</h2>
                <input type='text' onChange={_onChangeInverterProducts} value={product.inverter_product_output_voltage} name='inverter_product_output_voltage' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product output Frequency (Hz):</h2>
                <input type='text' onChange={_onChangeInverterProducts} value={product.inverter_product_output_frequency} name='inverter_product_output_frequency' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Max Input DC Current (A):</h2>
                <input type='number' onChange={_onChangeInverterProducts} value={product.inverter_product_max_input_current} name='inverter_product_max_input_current' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Max output Charge Current (A):</h2>
                <input type='number' onChange={_onChangeInverterProducts} value={product.inverter_product_max_output_current} name='inverter_product_max_output_current' className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
            </div>
          )) }
          
          <div className=' my-4 text-center ' >
            <button className=' f-f-r text-lg text-primary border border-primary  w-[204px] h-[38px]  ' >+ Add new Row</button>
          </div>
        </div> */}
        {/* Inverter Product(s) section edned */}

        {/* <div className='2xl:px-20 ' >
          <h2 className=" f-f-sm text-lg capitalize  text-primary  mt-10  text-center " >Mounting Product</h2>
          { mountingProducts.map((product, i) => (
            <div key={i} className="grid grid-cols-12 mt-0 gap-4 sm:gap-0    ">
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Name *:</h2>
                <input onChange={_onChangeMountingProducts} value={product.mounting_product_name} name="mounting_product_name" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product quantity *:</h2>
                <input type='number' onChange={_onChangeMountingProducts} value={product.mounting_product_quantity} name="mounting_product_quantity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Unit Capacity *:</h2>
                <input type='number' onChange={_onChangeMountingProducts} value={product.mounting_product_unit_capacity} name="mounting_product_unit_capacity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product label:</h2>
                <input type='text' onChange={_onChangeMountingProducts} value={product.mounting_product_label} name="mounting_product_label" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Comments or Notes:</h2>
                <input type='text' onChange={_onChangeMountingProducts} value={product.mounting_product_notes} name="mounting_product_notes" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Manufacturer:</h2>
                <input type='text' onChange={_onChangeMountingProducts} value={product.mounting_product_manufacturer} name="mounting_product_manufacturer" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Model:</h2>
                <input type='text' onChange={_onChangeMountingProducts} value={product.mounting_product_model} name="mounting_product_model" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Part Number:</h2>
                <input type='text' onChange={_onChangeMountingProducts} value={product.mounting_product_part_number} name="mounting_product_part_number" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Certifications:</h2>
                <input type='text' onChange={_onChangeMountingProducts} value={product.mounting_product_certifications} name="mounting_product_certifications" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Clear Height Minimum (Ft):</h2>
                <input type='number' onChange={_onChangeMountingProducts} value={product.mounting_min_clear_height} name="mounting_min_clear_height" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
            </div>
          )) }
          
          <div className=' my-4 text-center ' >
            <button className=' f-f-r text-lg text-primary border border-primary  w-[204px] h-[38px]  ' >+ Add new Row</button>
          </div>
        </div> */}
        {/*  Generator Product(s) section started */}
        {/* <div className='2xl:px-20 ' >
          <h2 className=" f-f-sm text-lg capitalize  text-primary  mt-10  text-center " >Generator Product</h2>
          { generatorProducts.map((product, i) => (
            <div key={i} className="grid grid-cols-12 mt-0 gap-4 sm:gap-0    ">
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Name *:</h2>
                <input onChange={_onChangeGeneratorProducts} value={product.generator_product_name} name="generator_product_name" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Quantity *:</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_product_quantity} name="generator_product_quantity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Unit Capacity *:</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_product_unit_capacity} name="generator_product_unit_capacity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product label:</h2>
                <input type='text' onChange={_onChangeGeneratorProducts} value={product.generator_product_label} name="generator_product_label" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Comments or Notes:</h2>
                <input type='text' onChange={_onChangeGeneratorProducts} value={product.generator_product_notes} name="generator_product_notes" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Total Capacity:</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_product_total_capacity} name="generator_product_total_capacity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Manufacturer:</h2>
                <input type='text' onChange={_onChangeGeneratorProducts} value={product.generator_product_manufacturer} name="generator_product_manufacturer" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Model:</h2>
                <input type='text' onChange={_onChangeGeneratorProducts} value={product.generator_product_model} name="generator_product_model" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Part Number:</h2>
                <input type='text' onChange={_onChangeGeneratorProducts} value={product.generator_product_part_number} name="generator_product_part_number" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Certifications:</h2>
                <input type='text' onChange={_onChangeGeneratorProducts} value={product.generator_product_certifications} name="generator_product_certifications" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Frequency (Hz):</h2>
                <input type='text' onChange={_onChangeGeneratorProducts} value={product.generator_frequency} name="generator_frequency" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Phase/Wire (A):</h2>
                <input type='text' onChange={_onChangeGeneratorProducts} value={product.generator_phase} name="generator_phase" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Rated Voltage (V):</h2>
                <input type='text' onChange={_onChangeGeneratorProducts} value={product.generator_rated_voltage} name="generator_rated_voltage" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Real Standby Power (kW):</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_real_standby_power} name="generator_real_standby_power" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Apparent Standby Power (kVA):</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_apparent_standby_power} name="generator_apparent_standby_power" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Real Prime Power (kW):</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_real_prime_power} name="generator_real_prime_power" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Apparent Prime Power (kVA):</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_apparent_prime_power} name="generator_apparent_prime_power" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Power Factor:</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_power_factor} name="generator_power_factor" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Anticipated Efficiency (%):</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_efficiency} name="generator_efficiency" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Max Current (A):</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_max_current} name="generator_max_current" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Continuous Current (A):</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_continuous_current} name="generator_continuous_current" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Breaker Current (A):</h2>
                <input type='number' onChange={_onChangeGeneratorProducts} value={product.generator_breaker_current} name="generator_breaker_current" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
            </div>
          )) }
          
          <div className=' my-4 text-center ' >
            <button className=' f-f-r text-lg text-primary border border-primary  w-[204px] h-[38px]  ' >+ Add new Row</button>
          </div>
        </div> */}
        {/* Generator Product(s) section edned */}
        {/*  Container Product(s) section started */}
        {/* <div className='2xl:px-20 ' >
          <h2 className=" f-f-sm text-lg capitalize  text-primary  mt-10  text-center " >Charge Controller Product</h2>
          { chargecontrollerProducts.map((product, i) => (
            <div key={i} className="grid grid-cols-12 mt-10 gap-4 sm:gap-0    ">
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Name *:</h2>
                <input type='text' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_product_name} name="chargecontroller_product_name" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none   "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Quantity *:</h2>
                <input type='number' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_product_quantity} name="chargecontroller_product_quantity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Unit Capacity *:</h2>
                <input type='number' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_product_unit_capacity} name="chargecontroller_product_unit_capacity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Label:</h2>
                <input type='text' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_product_label} name="chargecontroller_product_label" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Comments or Notes:</h2>
                <input type='text' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_product_notes} name="chargecontroller_product_notes" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Total Capacity:</h2>
                <input type='text' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_product_total_capacity} name="chargecontroller_product_total_capacity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Manufacturer:</h2>
                <input type='text' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_product_manufacturer} name="chargecontroller_product_manufacturer" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none   "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Model:</h2>
                <input type='text' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_product_model} name="chargecontroller_product_model" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Part Number:</h2>
                <input type='text' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_product_part_number} name="chargecontroller_product_part_number" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Product Certifications:</h2>
                <input type='text' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_product_certifications} name="chargecontroller_product_certifications" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 mr-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Max PV Capacity:</h2>
                <input type='number' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_max_pv_capacity} name="chargecontroller_max_pv_capacity" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
              <div className="  col-span-12 sm:col-span-6 ml-2 ">
                <h2 className=' f-f-sm text-2xl text-black mt-4 ' >Max String Length:</h2>
                <input type='number' onChange={_onChangeChargecontrollerProducts} value={product.chargecontroller_max_string_length} name="chargecontroller_max_string_length" className=" w-full  h-[56px] border border-grey bg-white-dark  px-4 outline-none  "></input>
              </div>
            </div>
          )) }
          
          <div className=' my-4 text-center ' >
            <button className=' f-f-r text-lg text-primary border border-primary  w-[204px] h-[38px]  ' >+ Add new Row</button>
          </div>
        </div> */}
        {/* Container Product(s) section edned */}

        {/* button started */}
        <div className=" mt-10 text-end  ">
          <ul className=" inline-flex  ">
            <li>
              {loading ? (
                <button className=" bg-gray text-lg f-f-r text-gray  w-[127px] h-[48px] rounded-[8px] captilize ">
                  Loading...
                </button>
              ) : (
                <button
                  onClick={submitScenarios}
                  className=" bg-primary text-lg f-f-r text-white  w-[127px] h-[48px] rounded-[8px] captilize "
                >
                  Done
                </button>
              )}
            </li>
            <li className=" ml-6 ">
              <button
                onClick={() => navigate(-1)}
                className=" border border-primary text-lg f-f-r text-primary  w-[127px] h-[48px] rounded-[8px] captilize "
              >
                Back
              </button>
            </li>
          </ul>
        </div>
        {/* button ended */}
      </div>
    </>
  );
}
